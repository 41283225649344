<!--
 * @Description: 素材审核
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-20 21:55:38
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/custom/examine/index.vue
-->
<template>
  <div class="app-wrapper wrapper-view">
    <div class="wrapper-title">素材审核列表</div>
    <el-form ref="queryForm" size="small" :inline="true" label-width="68px">
    </el-form>
    <el-table
      :data="tableData"
      stripe
      max-height="670"
      style="width: 100%; margin-top: 20px"
      v-loading="isLoading"
    >
      <el-table-column
        prop="company"
        label="用户企业名称"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="type_text"
        label="审核任务类型"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="count"
        label="包含数据量"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="{ row }">
            <span v-if="`${row.type}` === '5'">--</span>
            <span v-else>{{row.count}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="updated_at"
        label="审核时间"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column prop="state_text" label="任务状态" align="center">
      </el-table-column>
      <el-table-column prop="status" label="操作" align="center">
        <template slot-scope="{ row }">
          <div
            v-if="`${row.state}` === '1'"
            :class="['state-btn', 'primary', 'state-primary']"
            @click="handlePrimary(row)"
          >
            去审核
          </div>
          <div v-else :class="['state-btn']">已审核</div>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="page.total > 0"
      :total="page.total"
      :page.sync="page.page"
      :limit.sync="page.size"
      @pagination="getList()"
    />
  </div>
</template>

<script>
import { HTTP } from "../../../../utils/request";

const URL = {
  list: "api/operation/material/auditTask",
};
export default {
  data() {
    return {
      queryParams: {},
      tableData: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList(page = this.page.page, size = this.page.size) {
      this.isLoading = true;
      const res = await HTTP({
        url: URL.list,
        method: "get",
        data: {
          page,
          limit: size,
        },
      });
      let { total, per_page, current_page, data } = res || {};
      this.page = { total, page: current_page, size: +per_page };
      this.tableData = data || [];
      this.isLoading = false;
    },

    handlePrimary(item) {
      switch (`${item.type}`) {
        case "2":
          this.$rou;
          this.$router.push({
            name: "CoreWords",
            query: {
              id: item.id,
            },
          });
          break;
        case "3":
          this.$rou;
          this.$router.push({
            name: "Images",
            query: {
              id: item.id,
            },
          });
          break;
        case "4":
          this.$rou;
          this.$router.push({
            name: "Videos",
            query: {
              id: item.id,
            },
          });
          break;
          case "5":
          this.$rou;
          this.$router.push({
            name: "SeedKeyWords",
            query: {
              id: item.id,
            },
          });
          break;          
        default:
          break;
      }
    },

    //   handleOpts(type, row) {
    //     switch (type) {
    //       case "transform":
    //         this.transform = {
    //           show: true,
    //           data: {
    //             ...row,
    //           },
    //         };
    //         break;
    //       default:
    //         break;
    //     }
    //   },
  },
};
</script>

<style lang="scss" scoped>
.primary {
  cursor: pointer;
}
</style>
